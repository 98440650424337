import palette from "./palette"

export default {
  fontFamily: "Raleway, Arial",
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: -0.24,
    lineHeight: 40,
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: -0.24,
    lineHeight: 32,
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: -0.06,
    lineHeight: 28,
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: -0.06,
    lineHeight: 24,
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: -0.05,
    lineHeight: 20,
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: -0.05,
    lineHeight: 20,
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: 16,
    letterSpacing: -0.05,
    lineHeight: 25,
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: -0.05,
    lineHeight: 21,
  },
  body1: {
    color: palette.text.primary,
    fontSize: 14,
    letterSpacing: -0.05,
    lineHeight: 21,
  },
  body2: {
    color: palette.text.secondary,
    fontSize: 12,
    letterSpacing: -0.04,
    lineHeight: 18,
  },
  button: {
    color: palette.text.primary,
    fontSize: 14,
  },
  caption: {
    color: palette.text.secondary,
    fontSize: 11,
    letterSpacing: 0.33,
    lineHeight: 13,
  },
  overline: {
    color: palette.text.secondary,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0.33,
    lineHeight: 13,
    textTransform: "uppercase",
  },
}
